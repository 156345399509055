//Wrapper for highlights on hover link or circles

import React, { useEffect } from 'react'

const highlightColor = "#FEB561", mouseOutColor = "#000000";

const Connectivity = (WrappedComponent) => {
  const addHoverHandler = () => {
    var aTags = document.querySelectorAll('a')
    var circles = document.querySelectorAll('circle')

    function checkMatchOver(a, circle) { 
        if (a.pathname === "/notes/" + circle.classList[0])
            {
                a.style.backgroundColor = highlightColor; 
                if (circle.classList[1]!=="main")
                    {circle.style.fill = highlightColor}
                a.scrollIntoViewIfNeeded(true) 
            }
    }

    function checkMatchOut(a, circle) { 
        if (a.pathname === "/notes/" + circle.classList[0])
            {
                a.style.backgroundColor = "transparent"; 
                if (circle.classList[1]!=="main")
                    {circle.style.fill = mouseOutColor}
                a.scrollIntoViewIfNeeded(false)
            }
    }

    aTags.forEach((a) => {
      a.addEventListener('mouseover', () => {
        circles.forEach(circle => {
            checkMatchOver(a, circle);
        });
      });
    });


    circles.forEach((circle) => {
        circle.addEventListener('mouseenter', () => {
          aTags.forEach(a => {
            checkMatchOver(a, circle);
          });
        });
      });


    document.querySelectorAll('a').forEach((a) => {
        a.addEventListener('mouseout', () => {
        circles.forEach(circle => {
            checkMatchOut(a, circle);
        });
      });
    });

    circles.forEach((circle) => {
        circle.addEventListener('mouseout', () => {
          aTags.forEach(a => {
            checkMatchOut(a, circle);
          });
        });
      });


  };

  const PageWrapper = (props) => {
    useEffect(() => {
      addHoverHandler();
    }, []);

    return <WrappedComponent {...props}/>
  }

  return PageWrapper;
}

export default Connectivity;

let history =[];

if (typeof window !== `undefined` && window){
  console.log("in with add to history, window is defined")
  window.onload = (event) => {

    const pageTitle = document.title;
    const pageSlug = window.location.pathname;

    // Create an object with the page title and slug
    const pageInfo = { title: pageTitle, slug: pageSlug };

     history = JSON.parse(sessionStorage.getItem('history')) || [];
      sessionStorage.setItem('history', JSON.stringify([...history, pageInfo]));
  };
}

  export default history

import React from "react"
import { Link } from "gatsby"
// import history from "../components/withAddToHistory"

    const isPartiallyActive = ({
      isPartiallyCurrent
    }) => {
      return isPartiallyCurrent
        // ? { className: "active-header"}
        ? { style: {color: `#000`}}
        : {}
    }

    // const PartialNavLink = props => (
    //   <Link getProps={isPartiallyActive} {...props} />
    // )
const ListLink = props => (
  <li className="header-link"><Link getProps={isPartiallyActive} to={props.to}>{props.children}</Link>
  </li>
)


export default function Layout({ children }) {
  return (
    <div>
        <ul className="header-list">

          <ListLink to="/entanglement"> 
          <svg width="20" height="20" viewBox="-2 -4 20 20" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M3 1.75C2.30964 1.75 1.75 2.30964 1.75 3C1.75 3.69036 2.30964 4.25 3 4.25C3.69036 4.25 4.25 3.69036 4.25 3C4.25 2.30964 3.69036 1.75 3 1.75ZM0.25 3C0.25 1.48122 1.48122 0.25 3 0.25C4.51878 0.25 5.75 1.48122 5.75 3C5.75 4.51878 4.51878 5.75 3 5.75C1.48122 5.75 0.25 4.51878 0.25 3Z"></path><path d="M13 11.75C12.3096 11.75 11.75 12.3096 11.75 13C11.75 13.6904 12.3096 14.25 13 14.25C13.6904 14.25 14.25 13.6904 14.25 13C14.25 12.3096 13.6904 11.75 13 11.75ZM10.25 13C10.25 11.4812 11.4812 10.25 13 10.25C14.5188 10.25 15.75 11.4812 15.75 13C15.75 14.5188 14.5188 15.75 13 15.75C11.4812 15.75 10.25 14.5188 10.25 13Z"></path><path d="M10 12C9.44771 12 9 11.5523 9 11V5C9 3.34315 7.65685 2 6 2H5V4H6C6.55228 4 7 4.44772 7 5V11C7 12.6569 8.34315 14 10 14H11V12H10Z"></path>
            <circle fill="black" width ="20" height ="20"></circle>
            </svg></ListLink>
    
          <ListLink to="/notes/all-pages/"> 
          <svg width="20" height="20" viewBox="-2 -5 20 20"><path fillRule="evenodd" clipRule="evenodd" d="M2 3C2 1.89543 2.89543 1 4 1H10L14 5V13C14 14.1046 13.1046 15 12 15H4C2.89543 15 2 14.1046 2 13V3ZM9 3H4V13H12V6H9V3Z"></path><rect x="5" y="7" width="5" height="2"></rect><rect x="5" y="10" width="6" height="2"></rect></svg>
          </ListLink>


          <ListLink to="/notes/"> <svg width="20" height="20" viewBox="-2 -5 20 20" fill="currentColor" fillRule="evenodd" clipRule="evenodd"><path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"></path><path d="M8.00002 5C7.23823 5 6.75235 5.42428 6.33207 6.0547L4.66797 4.9453C5.24769 4.07572 6.26181 3 8.00002 3C8.85764 3 9.74527 3.22801 10.4006 3.82095C11.0961 4.45014 11.3871 5.36121 11.24 6.39142C11.0654 7.61316 10.1329 8.20968 9.62788 8.53274C9.61312 8.54219 9.59872 8.5514 9.58471 8.56038C9.28352 8.75365 9.13447 8.85248 9.03752 8.95193C9.01576 8.97425 9.00487 8.98888 9.00006 8.9961C9.00004 8.99719 9.00002 8.99848 9.00002 9V10H7.00002V9C7.00002 8.36831 7.27443 7.89539 7.60537 7.55588C7.87516 7.27912 8.21507 7.06207 8.45012 6.91197C8.46899 6.89992 8.48719 6.8883 8.50461 6.87712C9.08301 6.50598 9.22542 6.35112 9.26007 6.10858C9.32718 5.63879 9.19239 5.42486 9.05886 5.30405C8.88525 5.14699 8.54074 5 8.00002 5Z"></path><circle cx="8" cy="12" r="1.25"></circle></svg> </ListLink>
         
       </ul>

     <div className="content">
     {children}
    </div>
   </div>
  )
}

import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer";
import "./note-style.css";
import Layout from "../../components/note-layout"
import * as d3 from "d3";
import "./minigraph.css";
import history from "../../components/withAddToHistory";
import Connectivity from "../../components/connectivity";

const BrainNote = ({note}) => {

  let references = [];
  let referenceBlock;
  let tags = [];

  //inbound reference previews
  if (note.inboundReferencePreviews !== null) {
    references = note.inboundReferencePreviews.map((ref) => (
      <li key = {ref.source}>
      {/*temp-fix*/}
        <a href={"../notes/"+ref.source}>{ref.name}</a>
        <br />
        <div className="reflocation" dangerouslySetInnerHTML={{ __html: ref.previewHtml }} />
      </li>
    ));

    if (references.length > 0) {
      referenceBlock = (
        <>
          {/*<h2 id="linked-references">Linked References</h2>*/}
          <ul>{references}</ul>
        </>
      );
    }
  }

  //externalinbound reference previews
  let externalRefBlock = [];
  if (note.externalInboundReferences !== null) {
    let refs = note.externalInboundReferences.map((ref) => (
      <li>
        <b><a href={ref.sourceUrl}>
          {ref.siteName}/{ref.sourcePage}
        </a></b>
        <br />
        <div dangerouslySetInnerHTML={{ __html: ref.previewHtml }} />
      </li>
    ));
    if (refs.length > 0) {
      externalRefBlock = (
        <>
          <h2 id="external-references">External References</h2>
          <ul>{refs}</ul>
        </>
      );
    }
  }

  //linkified tag creation
  tags = note.linkifiedTags.map((tag) => (
    <span key={tag.hashtag}><a href={tag.sourceUrl}>{tag.hashtag}</a> &nbsp;</span>
  ));

    // breadcrumb creation
    let ss = []; 
    if (typeof window !== `undefined` && window){
      console.log("in brainnote, window is defined")
      if (window.sessionStorage.history) {
        ss = JSON.parse(window.sessionStorage.history); }
    }

    let breadcrumbs=[];
    const noOfCrumbs = 5
    if (ss.length) {
      //const filteredSS = ss.filter((item) => item !== ""); // Remove empty string items
        const crumbs = ss.slice(-noOfCrumbs);
         breadcrumbs = crumbs.map((element, index) => {
            let breadcrumb;
            if (element.title !== "Working Title" && element.title !=="") {
                      breadcrumb = (
                  <span key={element.slug}>
                    <a href={element.slug}>{element.title}</a>
                    {index === crumbs.length - 1 ? "" : " – "}
                    &nbsp;
                  </span>
              )
             }
            else if (element.title!=="Working Title" && element.title !=="" && element.slug)
            {
              breadcrumb = (
                <span key={element.slug}>
                  <a href={element.slug}>{ element.slug.split("/notes/") && element.slug.split("/notes/")[1].replace(/-/g, ' ')}</a>
                  {index === crumbs.length - 1 ? "" : " – "}
                  &nbsp;
                </span>
              )
            }

    return breadcrumb;
  });
    }


  //node and link maps 
  const nodes = [
    { id: note.slug, title: note.title, slug: note.slug, main: true},
    ...note.inboundReferences.map(ref => {
      return { id: ref, title: ref, slug: ref, inbound: true, main: false };
    }),
    ...note.outboundReferences.map(ref => {
      return {id: ref, title: ref, slug: ref, inbound: false, main: false};
    })
  ];

  const links = [
    ...note.inboundReferences.map(ref => {
      return { source: ref, target: note.slug, inbound: true};
    }),
    ...note.outboundReferences.map(ref => {
      return { source: note.slug, target: ref, inbound: false}
    })
  ];


  let  simulation;

  const svgRef = useRef();

  useEffect(() => {
    const width = 300;
    const height = 400;


    //drag initialization
    const drag = simulation => {
      function dragStarted(event) {
        document.querySelector("body").style.cursor = "pointer";
        if (!event.active) simulation.alphaTarget(0.3).restart();
        event.subject.fx = event.subject.x;
        event.subject.fy = event.subject.y;
      }
  
      function dragged(event) {
        event.subject.fx = event.x;
        event.subject.fy = event.y;
        console.log(event)
        document.querySelector("body").style.cursor = "grabbing";
      }
  
      function dragEnded(event) {
        document.querySelector("body").style.cursor = "default";
        if (!event.active) simulation.alphaTarget(0);
        event.subject.fx = event.x;
        event.subject.fy = event.y;
      }
  
      return d3.drag()
        .on("start", dragStarted)
        .on("drag", dragged)
        .on("end", dragEnded);
    } //NEW
  
    //force simulation
    const simulation = d3.forceSimulation(nodes)
      .force('link', d3.forceLink(links)
        .id(d => d.id)
        .distance((link) => {
            const randomDistance = Math.random() * 80 + 1 
          if (link.inbound) {
            return randomDistance + 90 ; // adjust the distance for inbound links
          } else {
            return randomDistance + 70; // adjust the distance for outbound links
          }
        })
        .strength((link) => {
          if (link.inbound) { 
            return 1.5; // adjust the distance for inbound links
          } else {
            return 2; 
          }
        })
      )
      .force('charge', d3.forceManyBody())
      .force('center', d3.forceCenter(width / 2, height / 2))
      .on('tick', ticked)
  
      //svg
      const svg = d3.select(svgRef.current)
      .attr('width', width)
      .attr('height', height);
  
      //link svg
      const link = svg.selectAll('line')
      .data(links)
      .enter()
      .append('line')
      .attr("stroke-opacity", 
        function(d) { 
          if (d.inbound) {return 0.8}
          else {return 0.1}
        })
      .attr("stroke-width", 
        function(d) { 
          if (d.inbound) {return 4 }
          else {return 10}
        })
      .attr("stroke-linecap", "round")
      .attr("stroke", "#000")
  
    //node svg
      const node = svg.selectAll('circle')
      .data(nodes)
      .enter()
      .append('circle')
      .attr("stroke", "#000")
      .attr("stroke-width", 1.5)
      .attr('r', d => d.main ? 10 : 5)
      .attr("class", d=> d.main ?  d.slug + " main" : d.slug)
      .attr("fill", d => d.main ? "#FEB561" : "#212121")
      .attr("cursor", "pointer")
      .on('click', function(d, i) {
        // setTimeout(() => {
          if (typeof window !== `undefined` && window){ 
        window.location.href = "../notes/" + i.slug;}
        // })
      })
      .call(drag(simulation)); //NEW
    
      //ticked
      function ticked() {
      link
        .attr("x1", function (d) { return d.source.x; })
        .attr("y1", function (d) { return d.source.y; })
        .attr("x2", function (d) { return d.target.x; })
        .attr("y2", function (d) { return d.target.y; });
  
      node
        .attr("cx", function (d) { return d.x; })
        .attr("cy", function (d) { return d.y; });
    } 
  
    return () => {
      simulation.stop(); //NEW
    }
  }, []);
  

  return (
<Layout>
<history/>
<div>
    <Helmet>
      <title>
        {note.title}
      </title>
    </Helmet>
    <div className="panel"></div>
    <div id="brainNote">
    <div className="metaData">
      <div className="breadCrumbs">{breadcrumbs.length !== 0 && <p className="crumb">
        <a href="/notes/all-pages">... &nbsp;</a>
        {breadcrumbs}</p>} </div>
    <h1 id="note-title">{note.title}</h1>
    <div className="tags">
        {tags.length !== 0 && <p id="tags">{tags}</p>}
    </div>
    </div>
  
    <div className="container-left">
      <div className="date">
          {note.date !== null && <p id="date">Date: {note.date}</p>}
      </div>
      
      <div className="reference-map"> 
          <svg ref={svgRef}></svg>
        </div>
    </div>
      <div className="container-right">
        {note.childMdx.rawBody !== "" && <div id="child-body"><MDXRenderer>{note.childMdx.body}</MDXRenderer></div>}
        {referenceBlock != null && <div className="reference-block block"> 
          <p className="blockcontent"> ALSO REFERENCED IN: </p>
          <hr></hr>
        {referenceBlock} {externalRefBlock} </div>}
        
      </div>
    </div>
</div>
</Layout>
  );
};

export default Connectivity(BrainNote);
// export default BrainNote;


//WITH GREIGE
//  const link = svg.selectAll('line')
// .data(links)
// .enter()
// .append('line')
// .attr("stroke-opacity", 0.9)
// .attr("stroke-width", 
//   function(d) { 
//     if (d.inbound) {return 4 }
//     else {return 10}
//   })
// .attr("stroke-linecap", "round")
// .attr("stroke",  function(d) { 
//   if (d.inbound) {return "#A08986"}
//   else {return "rgb(0,0,0,0.1"}
// })